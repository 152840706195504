<template>
  <v-menu offset-y offset-x top :nudge-width="240">
    <template v-slot:activator="{ on, attrs }">
      <v-fab-transition>
        <v-btn
          v-if="shots.length"
          color="pink lighten-2"
          dark
          fab
          absolute
          app
          small
          left
          style="left: 50px; bottom: 150px"
          v-bind="attrs"
          v-on="on"
        >
          <v-badge :content="shots.length" :value="shots.length > 0" left>
            <v-icon v-text="`mdi-heart-outline`" />
          </v-badge>
        </v-btn>
      </v-fab-transition>
    </template>
    <v-list three-line dense>
      <template v-for="(shot, index) in shots">
        <v-list-item :href="href(shot)" :key="index">
          <v-badge
            content="private"
            offset-x="40"
            offset-y="25"
            color="red"
            left
            :value="(shot.publishType & 1) != 0"
          >
            <v-list-item-avatar tile>
              <v-img :src="shot.thumbnailData.src" />
            </v-list-item-avatar>
          </v-badge>

          <v-list-item-content>
            <!-- <v-list-item-title>{{ shot.shortCode }}</v-list-item-title> -->
            <v-list-item-title>{{
              shot.thumbnailData.title | noData
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ shot.thumbnailData.description | noData }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>
              {{ $moment(shot.date).toDate() | fromNow }}
            </v-list-item-action-text>
            <v-icon v-text="`mdi-chevron-right`" />
          </v-list-item-action>
        </v-list-item>

        <v-divider :key="`divider_${index}`" inset></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  components: {},

  props: {},

  computed: {
    shots() {
      return this.$application.storage.shots.filter((a) => a.isFavorite);
    },
  },

  data: () => ({}),
  methods: {
    href(shot) {
      // こっちは管理者情報を付与しない
      let href = `/shot/${shot.shortCode}`;
      return href;
    },
  },
  async mounted() {},
};
</script>
