<template>
  <v-app>
    <!-- <v-app-bar app color="primary" dense flat>
      <v-app-bar-title class="white--text">
        <a href="/" class="white-text">test</a>
      </v-app-bar-title>
    </v-app-bar> -->

    <!-- <div :class="{ blur: true }"> -->

    <!-- <v-app-bar
      app
      prominent
      :src="`https://picsum.photos/1920/1080?random`"
      :height="50"
      flat
    > -->

    <v-app-bar app prominent :height="50" flat>
      <!-- <template v-slot:img="{ props }">
        <v-img
          v-bind="props"
          :gradient="'to top right, rgba(100,115,201,.7), rgba(25,32,72,.3)'"
        ></v-img>
      </template> -->
      <v-badge class="ma-0" content="Beta" offset-x="15" offset-y="15">
        <v-toolbar-title class="pa-0">
          <!-- <router-link to="/" class="d-flex align-center text-decoration-none">
          <v-img src="/img/icons/logo_transparent.png" height="50" width="50" />
        </router-link> -->
          <a href="/" class="d-flex align-center text-decoration-none">
            <v-img
              src="/img/icons/logo_transparent.png"
              height="50"
              width="50"
            />
          </a>
          <!-- <span>{{ this.$vuetify.breakpoint.name }}</span> -->
        </v-toolbar-title>
      </v-badge>

      <v-spacer />

      <!-- <v-card color="transparent" min-width="600" flat>
        <v-card-title class="d-flex justify-start"> -->

      <!-- shot の loader -->
      <v-dialog
        v-model="creator.show"
        width="70%"
        class="elevation-0"
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
            outlined
            elevation="0"
          >
            <v-icon v-text="`mdi-camera-outline`" left />
            shot
          </v-btn>
        </template>
        <v-card>
          <v-card-actions>
            <v-btn
              text
              color="warning"
              @click="creator.show = false"
              :disabled="creator.loading"
            >
              閉じる
            </v-btn>
            <v-spacer />

            <v-btn
              color="warning"
              outlined
              @click="resetCreator"
              :disabled="creator.loading"
            >
              リセット
            </v-btn>
          </v-card-actions>
          <v-card-title>
            <v-spacer />

            <span>URLを入力してSHOT!</span>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              class="text-center"
              :disabled="creator.loading"
              @submit.prevent="onCommit"
            >
              <v-card flat>
                <validation-observer ref="observer" v-slot="{ invalid }">
                  <template v-if="(creator.type & ShotTypes.Url) != 0">
                    <validation-provider
                      v-slot="{ errors }"
                      name="url"
                      rules="required|url|max:500"
                    >
                      <v-text-field
                        v-model="creator.url"
                        solo
                        flat
                        outlined
                        dense
                        maxlength="500"
                        counter=""
                        placeholder="https://"
                        append-icon="mdi-arrow-right-drop-circle-outline"
                        :error-messages="
                          concatErrors(errors, creator.error.errors.url)
                        "
                        clearable
                        @click:append="onCommit"
                      >
                        <!-- <template v-slot:prepend>
                  <v-fab-transition>
                    <v-icon
                      v-if="
                        Object.keys(
                          concatErrors(errors, creator.error.errors.url)
                        ).length > 0
                      "
                      color="red"
                      v-text="`mdi-alert-outline`"
                  /></v-fab-transition>
                </template> -->
                      </v-text-field>
                    </validation-provider>
                  </template>

                  <template v-else>
                    <v-list-item
                      v-if="
                        Array.isArray(creator.fileDatas) &&
                        creator.fileDatas.length > 0
                      "
                    >
                      <v-list-item-avatar color="grey" tile>
                        <v-img
                          v-if="
                            creator.fileDatas[0].file.type.startsWith('image')
                          "
                          :src="creator.fileDatas[0].data"
                        />
                        <v-icon
                          v-if="
                            creator.fileDatas[0].file.type.startsWith('text')
                          "
                          dark
                          v-text="`mdi-note-text-outline`"
                        />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          <span class="text-caption">
                            {{ creator.fileDatas[0].file.name }}
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-spacer />
                      <v-list-item-action>
                        <v-list-item-action-text>
                          {{ creator.fileDatas[0].file.size | fileSize }}
                        </v-list-item-action-text>
                        <span class="text-caption">
                          {{ creator.fileDatas[0].file.type }}
                        </span>
                      </v-list-item-action>
                    </v-list-item>
                    <v-divider />
                  </template>

                  <p class="my-4">
                    <strong>SHOT!</strong>ボタンを押したら<router-link
                      to="policy"
                      target="_blank"
                    >
                      利用規約 </router-link
                    >に同意したものとします
                  </p>
                  <p class="my-4">
                    スクリプティング行為が禁止されているサイトでSHOTしないようお願いします。
                  </p>
                  <v-card-actions class="pa-2">
                    <v-btn
                      color="primary"
                      :disabled="creator.loading"
                      outlined
                      @click.stop="onUpload('image/jpg')"
                    >
                      <v-icon dark v-text="`mdi-image`" />

                      画像選択</v-btn
                    >

                    <v-btn
                      color="primary"
                      :disabled="creator.loading"
                      outlined
                      @click.stop="onUpload('text/*')"
                    >
                      <v-icon dark v-text="`mdi-note-text-outline`" />
                      テキスト選択</v-btn
                    >
                    <!-- エラー表示 -->
                    <v-input :error-messages="creator.error.errors.file" />
                    <v-spacer />

                    <v-btn
                      color="primary"
                      :disabled="invalid"
                      :loading="creator.loading"
                      :ripple="creator.ripple"
                      @click.stop="onCommit"
                      >SHOT!</v-btn
                    >
                  </v-card-actions>
                </validation-observer>
              </v-card>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        :value="stepper.show"
        persistent
        width="50%"
        class="elevation-0"
      >
        <v-card>
          <v-card-text>
            <div class="text-center py-2">
              <div>{{ stepper.title }}</div>
              <div>
                <v-icon v-text="stepper.icon" size="100" />
              </div>
              <div class="mt-6">
                <span>{{ stepper.message }}</span>
              </div>

              <v-progress-linear
                :buffer-value="stepper.value"
                striped
                stream
              ></v-progress-linear>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- </v-card-title>
      </v-card> -->
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <!-- <v-speed-dial>
      <template v-slot:activator>
        <v-btn color="blue darken-2" dark fab>
          <v-icon> mdi-account-circle </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="green">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn fab dark small color="indigo">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab dark small color="red">
        <v-icon>mdi-delete</v-icon>
      </v-btn>
    </v-speed-dial> -->

    <v-footer padless app>
      <StorageShots />
      <StorageFavorites />
    </v-footer>
  </v-app>
</template>

<script>
// import Application from "@/applications/Application";

// vee-validate
// import { required } from "vee-validate/dist/rules";
// import { extend } from "vee-validate";

// // * カスタムルール
// extend("rule1", {
//   message: "rule12221",
//   async validate(value) {
//     // if (!required(value)) return false;
//     return value.length > 20;
//   },
// });

const { v4: uuidv4 } = require("uuid");

import { AppValidateException } from "@/exceptions/appExceptions";

import { uploaderAsync, readFileAsync, resizeAsync } from "@/util/file";

import StorageShots from "@/components/assets/storages/shots/Shot";

import StorageFavorites from "@/components/assets/storages/favorites/Favorite";

import { ShotTypes } from "@/types";

// 設定ファイル読み込み
import { webconfig } from "@/web-configs";

import Pusher from "pusher-js";
Pusher.logToConsole = webconfig.pusher.logToConsole;

var pusher = new Pusher(webconfig.pusher.apikey, {
  cluster: webconfig.pusher.cluster,
});

export default {
  components: { StorageShots, StorageFavorites },

  computed: {
    ShotTypes() {
      return ShotTypes;
    },
  },
  data: () => ({
    initializing: true,

    /** 作成 */
    creator: {
      show: false,
      loading: false,
      type: ShotTypes.Url,
      filesDatas: [],
      //
      url: "https://cdn.vuetifyjs.com/images/cards/cooking.png",

      error: {
        errors: {
          url: [],
          file: [],
        },
      },
    },

    stepper: {
      show: false,
      title: null,
      message: null,
      icon: "mdi-numeric-0-circle-outline",
      value: 0,
    },
  }),

  methods: {
    initProgress() {
      this.stepper.show = false;
      this.stepper.title = null;
      this.stepper.message = null;
      this.stepper.icon = `mdi-numeric-0-circle-outline`;
      this.stepper.value = 0;
    },
    setProgress(step, title, message) {
      const steps = 9;

      this.stepper.show = true;
      this.stepper.title = title;
      // this.stepper.message = message;
      this.stepper.message = `${step}/${steps} ${message}`;
      this.stepper.icon = `mdi-numeric-${step}-circle-outline`;
      this.stepper.value = (step / steps) * 100;
    },

    async onUpload(accept = "image/jpeg") {
      try {
        const files = await uploaderAsync({
          accept: accept,
          multiple: false,
        });
        //
        // .map を持たないためこのやり方で展開
        let uploads = [];
        for (let i = 0; i < files.length; i++) {
          uploads.push(files[i]);
        }

        // 一応ここでもバリデーションする
        for (let i = 0; i < uploads.length; i++) {
          const upload = uploads[i];
          if (
            ![
              "image/jpeg",
              "image/jpg",
              "image/png",
              "text/plain",
              "text/html",
            ].includes(upload.type)
          ) {
            const error = new AppValidateException();
            //
            error.errors["file"] = ["invalid content type."];
            throw error;
          }
        }

        let fileDatas = [];
        for (let i = 0; i < uploads.length; i++) {
          const upload = uploads[i];

          let d2 = null;
          if (upload.type.startsWith("image")) {
            const d1 = await readFileAsync(uploads[i]);
            d2 = await resizeAsync(d1, 150);
          }

          fileDatas.push({
            file: upload,
            data: d2,
          });
        }
        //
        this.creator.type = ShotTypes.Image;
        // this.creator.fileDatas = fileDatas;
        this.$set(this.creator, "fileDatas", fileDatas);
        //
        this.creator.error.errors.file = [];
      } catch (error) {
        // 画面エラー表示
        this.creator.error = this.sanitizeError(this.creator.error, error);
      }
    },

    async onCommit() {
      // ローディング開始
      this.creator.loading = true;
      //
      try {
        // recaptchaAsyncのトークンは長すぎるのでこちらで代用
        const token = uuidv4().replace(/-/g, "");

        // ここでユーザ登録
        {
          // recaptchaToken発行
          const recaptchaToken = await this.recaptchaAsync("postsite"); // postsite以外通らない？？
          await this.$application.storage.createUser(recaptchaToken);
        }

        // recaptchaのコードをtoken(requestCode)として使用する
        const recaptchaToken = await this.recaptchaAsync("postsite");

        switch (this.creator.type) {
          // url
          case ShotTypes.Url:
            {
              // バリデーション
              if (!(await this.$refs.observer.validate())) return;

              // shot処理
              await this.createShot2Async(
                //await this.createShotAsync(
                this.creator.url,
                token,
                recaptchaToken,
                false
              );
            }
            break;

          case ShotTypes.Image: // image
            {
              // ファイルアップロード実施
              const success = await this.$application.http.postUpload(
                "shot/upload",
                {
                  token: token,
                  contentTypes: this.creator.fileDatas.map((a) => a.file.type),
                },
                this.creator.fileDatas.map((a) => a.file)
              );

              // shot処理(プライベートファイルモードで送る)
              await this.createShot2Async(
                //await this.createShotAsync(
                success.data.url,
                token,
                recaptchaToken,
                true
              );
            }
            break;
        }
      } catch (error) {
        // 画面エラー表示
        this.creator.error = this.sanitizeError(this.creator.error, error);
        // プログレスを初期化し止める
        this.initProgress();

        // ローディング開始
        this.creator.loading = true;

        // 初期化
        this.stepper.show = false;
        this.creator.loading = false;
      }
    },

    async createPusher(token) {
      // pusherのコールバック初期化
      // 登録しているサブクライブをすべて解除にする
      pusher.unsubscribe();

      // tokenでサブクライブを作成
      var channel = pusher.subscribe(token);
      // 各種コールバック登録(全9種)
      for (let i = 1; i <= 9; i++) {
        channel.bind(`${i}`, (val) => {
          const data = JSON.parse(val.message);

          this.setProgress(i, data.title, data.message);
        });
      }

      // cleanup完了時
      channel.bind("cleanup", () => {
        console.debug("pusher cleanup.");

        // 成功時も失敗時もこれがくるのでここでプログレス閉じる(初期化)
        this.initProgress();
      });

      // cleanup失敗時
      channel.bind("cleanupError", () => {
        console.debug("pusher cleanupError.");

        // クリーンアップにも失敗していた場合
        this.initProgress();
      });

      // エラー時
      channel.bind("error", (val) => {
        console.debug("pusher error.", val);

        const error = JSON.parse(val.message);

        //
        const message = this.$application.app.trans(error.code, {
          title: error.title,
          message: error.message,
          approach: error.approach,
        });

        // トースターを表示
        this.$application.app.showErrorToaster(
          message.code,
          message.title,
          message.message
        );

        //
        this.initProgress();

        this.creator.loading = false;

        // サブクライブ解除
        pusher.unsubscribe(token);
      });
      // 完了時
      channel.bind("complete", (val) => {
        console.debug("pusher complete.", val);

        const data = JSON.parse(val.message);

        // 成功しているのでcreatorを初期化し閉じる
        this.resetCreator();
        this.creator.show = false;
        this.creator.loading = false;
        //
        this.initProgress();
        // サブクライブ解除
        pusher.unsubscribe(token);

        // 遷移
        this.completeAndMove(data.shortCode, data.secretCode);
      });
    },

    /**
     * Pusherを使用しShotします
     * @param {*} url
     * @param {*} token
     * @param {*} recaptchaToken
     */
    async createShot2Async(url, token, recaptchaToken) {
      //
      this.setProgress(0, "ready", "準備中");

      //
      await this.createPusher(token);
      // shotのPost
      await this.$application.http.post("shot", {
        token: token,
        url: url,
        recaptchaToken: recaptchaToken,
      });
    },

    /**
     * APIをリクエストしShotします
     * APIアクセスではタイムアウトするAPIもあるのでデバッグ用です
     * @param {*} url
     * @param {*} token
     * @param {*} recaptchaToken
     */
    async createShotAsync(url, token, recaptchaToken) {
      //
      // this.$refs.form.reset();

      // 生成されたコード
      let siteCode = null;
      let queryCode = null;
      let shotCode = null;
      let shortCode = null;
      let secretCode = null;

      // クライアント側でチェックはCORSにより行えない
      try {
        // const steps = 6;
        // let step = 1;

        // ローディング開始
        this.creator.loading = true;

        // recaptcha準備

        // プログレス
        this.setProgress(1, "template", "テンプレート作成中");

        // サイト情報チェック＆登録
        {
          let success = await this.$application.http.post("site", {
            token: token,
            url: url,
            recaptchaToken: recaptchaToken,
          });
          let data = success.data;

          //
          siteCode = data.siteCode;
          queryCode = data.queryCode;
          shotCode = data.shotCode;
          shortCode = data.shortCode;
          secretCode = data.secretCode;
        }

        console.debug("siteCode", siteCode);
        console.debug("queryCode", queryCode);
        console.debug("shotCode", shotCode);
        console.debug("shortCode", shortCode);
        console.debug("secretCode", secretCode);

        // スクショをとる
        // queryStringはすべてLower
        {
          // プログレス
          this.setProgress(2, "Shoting", "スクリーンショット作成中");

          const url = `${webconfig.api.screenshotUrl}/${siteCode}/${queryCode}/${shotCode}`;
          // let success =
          await this.$application.http.post(url, {
            token: token,
            secretcode: secretCode,
          });
        }

        // 解析
        {
          // プログレス
          this.setProgress(3, "Analysing", "情報解析中");

          //
          // const url = `https://analyse.siteshot.link/${siteCode}/${queryCode}/${shotCode}`;
          // // let success =
          // await this.$application.http.post(url, {
          //   token: token,
          //   secretcode: secretCode,
          // });

          //
          await this.$application.http.post("shot/face", {
            token: token,
            siteCode: siteCode,
            queryCode: queryCode,
            shotCode: shotCode,
            secretCode: secretCode,
          });
        }

        // 描画
        // queryStringはすべてLower
        {
          // プログレス
          this.setProgress(4, "Drawing", "ファイル作成中");

          const url = `${webconfig.api.drawUrl}/${siteCode}/${queryCode}/${shotCode}`;
          // let success =
          await this.$application.http.post(url, {
            token: token,
            secretcode: secretCode,
          });
        }

        // 色分析
        {
          // プログレス
          this.setProgress(5, "Coloring", "色分析中");

          //
          await this.$application.http.post("shot/color", {
            token: token,
            siteCode: siteCode,
            queryCode: queryCode,
            shotCode: shotCode,
            secretCode: secretCode,
          });
        }

        // サムネ作成
        {
          // プログレス
          this.setProgress(6, "Thumbnailing", "サムネイル画像作成中");
          //

          await this.$application.http.post("shot/thumbnail", {
            token: token,
            siteCode: siteCode,
            queryCode: queryCode,
            shotCode: shotCode,
            secretCode: secretCode,
          });

          await this.$application.http.postUpload("shot/watermark", {
            token: token,
            siteCode: siteCode,
            queryCode: queryCode,
            shotCode: shotCode,
            secretCode: secretCode,
            default: true, // デフォルトロゴのウォーターマーク設定を強制する
          });
        }

        // 文字解析
        {
          // プログレス
          this.setProgress(7, "Detect", "文字解析");
          //

          await this.$application.http.post("shot/comprehend", {
            token: token,
            siteCode: siteCode,
            queryCode: queryCode,
            shotCode: shotCode,
            secretCode: secretCode,
          });
        }

        // スクレイピング
        {
          // プログレス
          this.setProgress(8, "Scraping", "スクレイピング");
          //

          await this.$application.http.post("shot/scrape", {
            token: token,
            siteCode: siteCode,
            queryCode: queryCode,
            shotCode: shotCode,
            secretCode: secretCode,
          });
        }

        // コンテンツ情報保存
        {
          // プログレス
          this.setProgress(9, "Saving", "コンテンツ保存中");
          //

          await this.$application.http.post("shot/save", {
            token: token,
            siteCode: siteCode,
            queryCode: queryCode,
            shotCode: shotCode,
            secretCode: secretCode,
          });
        }

        // 遷移
        this.completeAndMove(shortCode, secretCode);
      } catch (error) {
        // 画面エラー表示
        this.creator.error = this.sanitizeError(this.creator.error, error);
      } finally {
        // 後処理
        {
          //
          if (
            token != null &&
            siteCode != null &&
            queryCode != null &&
            shotCode != null &&
            secretCode != null
          ) {
            try {
              await this.$application.http.post("shot/cleanup", {
                token: token,
                siteCode: siteCode,
                queryCode: queryCode,
                shotCode: shotCode,
                secretCode: secretCode,
              });
            } catch (error) {
              console.debug(error);
            } finally {
              // クリーンアップ完了時のみcreator画面を閉じる
              this.resetCreator();
              this.creator.show = false;
            }
          }

          // 初期化
          this.stepper.show = false;
          this.creator.loading = false;
        }
      }
    },

    resetCreator() {
      // this.creator.show = false;
      this.creator.type = ShotTypes.Url;
      this.creator.url = null;
      this.creator.uploads = [];
      this.creator.fileDatas = [];
      this.creator.error = {
        errors: {
          url: [],
        },
      };
      this.$refs.observer.reset();
    },

    // 完了後shot画面へ遷移します
    completeAndMove(shortCode, secretCode) {
      // 遷移
      this.$router.push(`/shot/${shortCode}/${secretCode}`);
    },
  },

  async mounted() {
    await this.createPusher("my-event");
  },
};
</script>

<style lang="scss" scoped>
.blur {
  text-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
  filter: blur(3px);
}

.routerLink {
  color: white;
  text-decoration: none;
}
</style>
