<template>
  <v-menu offset-y offset-x top :nudge-width="240">
    <template v-slot:activator="{ on, attrs }">
      <v-fab-transition>
        <v-btn
          v-if="shots.length"
          color="blue"
          dark
          fab
          absolute
          app
          small
          left
          style="left: 50px; bottom: 50px"
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            :content="publicShots.length"
            :value="publicShots.length > 0"
            left
          >
            <v-badge
              :content="privateShots.length"
              :value="privateShots.length > 0"
              color="red"
            >
              <v-icon v-text="`mdi-format-list-checks`" />
            </v-badge>
          </v-badge>
        </v-btn>
      </v-fab-transition>
    </template>
    <v-list three-line dense>
      <template v-for="(shot, index) in shots">
        <v-list-item :href="href(shot)" :key="index">
          <v-badge
            content="private"
            offset-x="40"
            offset-y="25"
            color="red"
            left
            :value="(shot.publishType & 1) != 0"
          >
            <v-list-item-avatar tile>
              <v-img :src="shot.thumbnailData.src" />
            </v-list-item-avatar>
          </v-badge>

          <v-list-item-content>
            <!-- <v-list-item-title>{{ shot.shortCode }}</v-list-item-title> -->
            <v-list-item-title>{{
              shot.thumbnailData.title | noData
            }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ shot.thumbnailData.description | noData }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text>{{
              $moment(shot.date).toDate() | fromNow
            }}</v-list-item-action-text>
            <v-list-item-action-text
              v-if="(shot.publishType & (1 << 1)) != 0"
              class="ttext-caption"
            >
              <span>公開:</span>
              <span>{{ $moment(shot.publishDate).toDate() | fromNow }}</span>
            </v-list-item-action-text>
            <v-icon v-text="`mdi-chevron-right`" />
          </v-list-item-action>
        </v-list-item>

        <v-divider :key="`divider_${index}`" inset></v-divider>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: "Shot",
  components: {},

  props: {},

  computed: {
    shots() {
      return this.$application.storage.shots.filter(
        (a) => (a.publishType & (1 | (1 << 1))) != 0
      );
    },
    publicShots() {
      return this.shots.filter((a) => (a.publishType & (1 << 1)) != 0);
    },
    privateShots() {
      return this.shots.filter((a) => (a.publishType & 1) != 0);
    },
  },

  data: () => ({}),
  methods: {
    href(shot) {
      let href = `/shot/${shot.shortCode}`;
      if (shot.secretCode) href += `/${shot.secretCode}`;
      return href;
    },
  },
  async mounted() {},
};
</script>
